// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kiitos-js": () => import("./../../../src/pages/kiitos.js" /* webpackChunkName: "component---src-pages-kiitos-js" */),
  "component---src-pages-kurssit-js": () => import("./../../../src/pages/kurssit.js" /* webpackChunkName: "component---src-pages-kurssit-js" */),
  "component---src-pages-tietoturvaseloste-js": () => import("./../../../src/pages/tietoturvaseloste.js" /* webpackChunkName: "component---src-pages-tietoturvaseloste-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */)
}

